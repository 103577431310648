/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Después de instalar Ubuntu 12.04 viene lo mas divertido, configurarlo a tu gusto. Esta vez he tirado mucho del centro de software, así que vais a ver pocos “apt-get”. Como siempre esto es una elección totalmente personal pero si a alguien le sirve de guía, mejor que mejor."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://blog.desdelinux.net/consejos-practicos-para-optimizar-ubuntu-12-04/"
  }, "Una buena guía"), " para eliminar servicios que no utilicemos, yo le añadiría eliminar el cliente de microbloggin porque no tengo y cualquier otra que no nos interese."), "\n", React.createElement(_components.li, null, "Si no hemos creado una partición swap en la instalación podemos fácilmente ", React.createElement(_components.a, {
    href: "http://www.guia-ubuntu.org/index.php?title=Crear_un_archivo_de_swap"
  }, "crear un archivo que realice esta función"), "."), "\n", React.createElement(_components.li, null, "Siempre es útil saber las temperaturas que alcanza tu ordenador así como la velocidad de los ventiladores y todos los datos a los que podamos acceder. ", React.createElement(_components.a, {
    href: "http://www.ubuntu-guia.com/2009/09/monitorizar-las-temperaturas-y-sensores.html"
  }, "Aquí"), " enlazo una guía para configurar todo este tema. Después de probar varios visualizadores de esta información, recomiendo ", React.createElement(_components.strong, null, "Psensor"), ", por su sencillez y buena personalización, para personalizar más aún lo mejor es usar Conky pero es más complejo de configurar a tu gusto. En mi placa base para que funcione lm-sensors necesito deshabilitar el control mediante ACPI, que por otro lado al ser tan básica la placa base no está controlando nada, por eso no tengo nada que perder. En otros casos puede no ser recomendable, los ventiladores se pueden parar en el inicio y eso no es nada bueno. Para hacer esto he seguido ", React.createElement(_components.a, {
    href: "http://www.webupd8.org/2009/10/make-lm-sensors-work-in-ubuntu-karmic.html"
  }, "estos pasos"), "."), "\n", React.createElement(_components.li, null, "Instalar ", React.createElement(_components.strong, null, "Grub Customizer"), ", muy cómodo para editar el Grub."), "\n", React.createElement(_components.li, null, "Como ya comenté como lector RSS instalé ", React.createElement(_components.strong, null, "Liferea"), ", ¿como puede ser que Ubuntu no lo tenga instalado por defecto?¿Software libre sin agregador RSS? Que incongruencia. Podemos también configurar el botón RSS de Firefox para que ", React.createElement(_components.a, {
    href: "http://www.k4ch0.org/2009/03/09/agregar-un-canal-rss-en-liferea-desde-firefox/"
  }, "agregue automáticamente los canales a Liferea"), "."), "\n", React.createElement(_components.li, null, "Instalar ", React.createElement(_components.strong, null, "Calibre"), ", no hay nada mejor para gestionar nuestra biblioteca virtual."), "\n", React.createElement(_components.li, null, "¡Cuando pinchas en la barra de direcciones Firefox no se selecciona entera! ", React.createElement(_components.a, {
    href: "http://placerdigital.net/como-seleccionar-toda-la-barra-de-direcciones-con-1-click-en-firefox-y-ubuntu/"
  }, "Pues a arreglarlo"), "."), "\n"), "\n", React.createElement(_components.p, null, "Con esto ya hay bastante por hacer igual otro día escribo sobre la personalización de Unity, que es un tema que tiene su miga,"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
